import {
  CopyOutlined,
  GoogleOutlined,
  WindowsOutlined,
  YahooOutlined,
} from "@ant-design/icons";
import { Button, Flex, Modal, message } from "antd";
import { EventType, ShareModal, trackEvent } from "../../utils/analytics";
import styles from "./share.module.scss";
type TShareProps = {
  openShare: boolean;
  setOpenShare: (value: boolean) => void;
};
const Share = ({ openShare, setOpenShare }: TShareProps) => {
  const componentName = "share";
  const handleCopyLink = () => {
    navigator.clipboard.writeText("https://getnudgeai.com");
    trackEvent(EventType.SHARE_MODAL_OPEN, {
      shareModalType: ShareModal.COPY_LINK,
    });
    message.success("Link copied to clipboard");
  };
  const openEmailClient = (service: "gmail" | "yahoo" | "outlook") => {
    const subject = "Check out Nudge AI";
    const body =
      "Hey there,\n\nI wanted to share this amazing AI tool called Nudge AI with you. It's designed to help therapists like us in our practice.\n\nYou can check it out at https://getnudgeai.com\n\nBest regards,\n[Your Name]";
    let mailtoLink = "";

    switch (service) {
      case "gmail":
        mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${encodeURIComponent(
          subject,
        )}&body=${encodeURIComponent(body)}`;
        trackEvent(EventType.SHARE_MODAL_OPEN, {
          shareModalType: ShareModal.GMAIL,
        });
        break;
      case "yahoo":
        mailtoLink = `https://compose.mail.yahoo.com/?to=&subject=${encodeURIComponent(
          subject,
        )}&body=${encodeURIComponent(body)}`;
        trackEvent(EventType.SHARE_MODAL_OPEN, {
          shareModalType: ShareModal.YAHOO,
        });
        break;
      case "outlook":
        mailtoLink = `https://outlook.live.com/mail/0/deeplink/compose?to=&subject=${encodeURIComponent(
          subject,
        )}&body=${encodeURIComponent(body)}`;
        trackEvent(EventType.SHARE_MODAL_OPEN, {
          shareModalType: ShareModal.OUTLOOK,
        });
        break;
      default:
        break;
    }

    window.open(mailtoLink, "_blank");
  };
  return (
    <Modal open={openShare} onCancel={() => setOpenShare(false)} footer={null}>
      <Flex justify="center">
        <h4 className={styles[`${componentName}__share-title`]}>
          Share some nudge ❤️ with fellow therapists!
        </h4>
      </Flex>
      <Flex justify="center">
        <div className={styles[`${componentName}__share-title`]}>
          Share nudge with fellow therapists and we'll make sure you get
          credited if they subscribe!
        </div>
      </Flex>
      <Flex justify="center">
        <Button icon={<CopyOutlined />} type="primary" onClick={handleCopyLink}>
          Copy link to share
        </Button>
      </Flex>
      <Flex justify="center">
        <div className={styles[`${componentName}__share-title`]}>
          Or share via email:
        </div>
      </Flex>
      <Flex justify="center" gap={20}>
        <div>
          <Button
            icon={<GoogleOutlined />}
            onClick={() => openEmailClient("gmail")}
          >
            Gmail
          </Button>
        </div>
        <div>
          <Button
            icon={<YahooOutlined />}
            onClick={() => openEmailClient("yahoo")}
          >
            Yahoo Mail
          </Button>
        </div>
        <div>
          <Button
            icon={<WindowsOutlined />}
            onClick={() => openEmailClient("outlook")}
          >
            Outlook
          </Button>
        </div>
      </Flex>
    </Modal>
  );
};
export default Share;
