export type TNotes = {
  client_id?: number;
  name?: string;
  summary?: string;
  audio_id?: number | string;
  id: number;
  title: string;
  note_type: number;
  uploaded_at: string;
  assessment: string;
  error_status: number;
  category_type: number;
  language_type: number;
  status: number;
  is_read: boolean;
  stats_transcribe_start_at: string;
  stats_generate_note_start_at: string;
  modality_type: number;
  duration_seconds: number;
  subject: string;
  intake_note: string;
  eap_intake_note: string;
  psych_intake_note: string;
  psych_followup_note?: string;
  data: string;
  document?: string;
  emdr_note: string;
  avs_email: string;
  objective: string;
  plan: string;
  gender_type: number;
  client?: number;
  location_type?: number;
  duration_type?: number;
  duration_addon_type?: number;
  client_name?: string;
  session_insights?: string;
  stats_generate_note_end_at: string;
  treatment_plan?: string;
  sol_psych_intake_note?: string;
  sol_psych_followup_note?: string;
  sol_therapy_intake_note?: string;
  sol_therapy_followup_note?: string;
  psychotherapy_minutes?: number;
  psychotherapy_minutes_explanation?: string;
  is_incapture?: boolean;
  system_metadata?: {
    [key: string]: any;
  };
};

export type Tdsm = {
  id: number;
  code: string;
  name: string;
};

export type TClient = {
  id: number;
  first_name: string;
  last_name: string;
};

export type TRecordingDetailsType = {
  title: string;
  note_type: number;
  category_type: number;
  modality_type: string;
  gender_type: number;
  language_type: number;
  location_type?: number;
  duration_type?: number;
  duration_addon_type?: number;
  client_id?: number;
  description?: string;
  fileList?: any[];
  current_recording_id?: number;
};

export type AppState = {
  isCreateClientDrawerVisible: boolean;
  isCustomiseSectionVisible: boolean;
  selectedClient: TClientForm | null;
  uploadInProgress: boolean;
  isEditNote: boolean;
  isCreateModalVisible: boolean;
  isSubmissionInProgress: boolean;
  selectedNoteForEditing: TNotes | null;
  isNotesViewVisible: boolean;
  uploadType: number;
  showRecordingView: boolean;
  loadingNotes: boolean;
  currentPage: number;
  isDemoPath: boolean;
  isMobilePath: boolean;
  showDemoSignupModal: boolean;
  addClientView: boolean;
  demoState: {
    isClientModalOpen: boolean;
    isNoteModalOpen: boolean;
    clientForm: TClientForm | null;
    recordingDetails: TRecordingDetailsType | null;
  };
};

export type TClientForm = {
  id?: number;
  uuid?: string; 
  name: string;
  category_type: number;
  gender_type: number;
  language_type: number;
  diagnosis_ids?: string[] | number[];
  refer_type?: number;
  progress_summary?: TProgressSummary;
  progress_summary_last_updated_at?: string;
  diagnoses?: Tdsm[];
};

export type TProgressSummary = {
  next_steps: string;
  primary_concern: string;
  session_summary: string;
};

export enum ModalityMode {
  audio = 0,
  dictate = 1,
  write = 2,
  upload = 3
}
